<template>
  <div v-bind:id="id" class="unlayer-editor"></div>
</template>

<script>
import { loadScript } from "./loadScript.js";
import pkg from "../../../package.json";

let lastEditorId = 0;

export default {
  name: "EmailEditor",
  props: {
    editorId: String,
    options: Object,
    projectId: Number,
    tools: Object,
    appearance: Object,
    locale: String,
    MCEVariable: Array,
  },
  data() {
    return { variable: [] };
  },
  computed: {
    id() {
      return this.editorId || `editor-${++lastEditorId}`;
    },
  },
  mounted() {
    loadScript(this.loadEditor.bind(this));
  },

  methods: {
    addVariable() {
      let obj = {};
      for (const val of this.MCEVariable) {
        obj[val] = { name: val, value: `{{${val}}}` };
      }
      // let val = this.MCEVariable.map((el) => {
      //   return { name: el, value: `{{${el}}}` };

      this.editor.setMergeTags(obj);
    },
    loadEditor() {
      const options = this.options || {};
      if (this.projectId) {
        options.projectId = this.projectId;
      }

      if (this.tools) {
        options.tools = this.tools;
      }

      if (this.appearance) {
        options.appearance = this.appearance;
      }

      if (this.locale) {
        options.locale = this.locale;
      }

      this.editor = unlayer.createEditor({
        ...options,
        id: this.id,
        displayMode: "email",
        source: {
          name: pkg.name,
          version: pkg.version,
        },
        mergeTagsConfig: {
          sort: false,
        },
        editor: {
          autoSelectOnDrop: true,
        },
        transformer: (values, source) => {
          const { name, value, data } = source;

          // Transform the values here
          let newValues = {
            ...values,
          };

          // Return updated values
          return newValues;
        },
        customJS: ["./custom.js"],
        tools: {
          "custom#my_tool": {
            data: {
              name: "John Doe",
              age: "27",
              photo: "https://picsum.photos/id/1005/200",
            },
            properties: {
              occupation: {
                editor: {
                  data: {
                    options: [
                      {
                        label: "Software Engineer",
                        value: "Software Engineer",
                      },
                      { label: "Product Manager", value: "Product Manager" },
                      { label: "CTO", value: "CTO" },
                      { label: "CEO", value: "CEO" },
                      { label: "Other", value: "Other" },
                    ],
                  },
                },
              },
            },
          },
        },
      });

      this.$emit("load");
      this.editor.addEventListener("editor:ready", () => {
        this.$emit("ready");
      });

      // this.editor.setMergeTags({
      //   first_name: {
      //     name: "First Name",
      //     value: "{{first_name}}",
      //     sample: "John",
      //   },
      //   last_name: {
      //     name: "Last Name",
      //     value: "{{last_name}}",
      //     sample: "Doe",
      //   },
      // });
      this.addVariable();
      // this.editor.addEventListener("design:updated", function (data) {
      //   // Design is updated by the user
      //   var type = data.type; // body, row, content
      //   var item = data.item;
      //   var changes = data.changes;
      //   changes.value = "test";
      // });
    },
    loadDesign(design) {
      this.editor.loadDesign(JSON.parse(design));
    },
    saveDesign(callback) {
      this.editor.saveDesign(callback);
    },
    exportHtml(callback) {
      this.editor.exportHtml(callback);
    },
    createTools() {
      unlayer.registerTool({
        name: "my_tool",
        label: "My Tool",
        icon: "fa-smile",
        supportedDisplayModes: ["web", "email"],
        options: {
          occupation: {
            title: "Occupation",
            position: 1,
            options: {
              occupation: {
                label: "Occupation",
                defaultValue: "Software Engineer",
                widget: "dropdown",
              },
            },
          },
        },
        values: {},
        renderer: {
          Viewer: unlayer.createViewer({
            render(values) {
              return `<div>
          <img src="${values.data.photo}" />
          <div>My name is <strong>${values.data.name}</strong> and I am <strong>${values.data.age}</strong> years old.</div>
          <div>My occupation is <strong>${values.occupation}</strong>.</div>
        </div>`;
            },
          }),
          exporters: {
            web: function (values) {
              return `<div>
          <img src="${values.data.photo}" />
          <div>My name is <strong>${values.data.name}</strong> and I am <strong>${values.data.age}</strong> years old.</div>
          <div>My occupation is <strong>${values.occupation}</strong>.</div>
        </div>`;
            },
            email: function (values) {
              return `<div>
          <img src="${values.data.photo}" />
          <div>My name is <strong>${values.data.name}</strong> and I am <strong>${values.data.age}</strong> years old.</div>
          <div>My occupation is <strong>${values.occupation}</strong>.</div>
        </div>`;
            },
          },
          head: {
            css: function (values) {},
            js: function (values) {},
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.unlayer-editor {
  flex: 1;
  display: flex;
}
</style>
